
const Careers = () => {
  return (
    <div>
      Careers
    </div>
  )
}

export default Careers
