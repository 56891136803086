import React from 'react'
import relayclassic from "../image/relayclassic.jpg";
import relaycompact from "../image/relaycompact.jpg";
import relaytimer from "../image/relaytimer.jpg";


const OverloadRelays = () => {
  return (
<>
      <header>
        <h3 className="centered-text">Overload Relays and Timers</h3>
      </header>
      <div className="description">
        <p>
          Apex Bi-metal<b> Overload Relay</b>  is a type of protective device used in electric motor control systems 
          to prevent motors from overheating and sustaining damage due to excessive current flow. 
          It operates based on the principle of thermal expansion of metals.
        </p>
        <div>
        imetal Overload Relay Definition:

A bimetal overload relay is an electromechanical device that protects electric motors from damage caused by excessive
 current or overload conditions. It utilizes a bimetallic strip composed of two different metals with 
 distinct thermal expansion rates. When an overcurrent situation occurs, the heat generated by the current
  flow causes the bimetallic strip to bend or deflect due to the differing expansion rates of the metals. 
  This bending action triggers a mechanism that interrupts the power supply to the motor, 
  effectively preventing overheating and potential damage. Bimetal overload relays are widely 
  used in motor starters and are valued for their reliability, simplicity, and ability to 
  provide accurate thermal protection based on the motor’s operating conditions.
        </div>
      </div>
      <div className="variant-container">
        <div className="variant-item">
          <div className="product-description">
            <div>
              <b>Apex COMMANDER Contactor</b> series has a modular design.
              Designed to perform in rugged conditions, it comes ready with
              1NO+1NC arrangement and various NO/NC arrangements using auxiliary
              contact blocks. These auxiliary contact blocks are quick and simple
              to mount. Apex Single Phase Submersible Pump Control Panel is useful
              where the appliance in question is uses Single Phase connection up
              to 5 H.P.
            </div>
            <div>
              Also, the design specifically emphasizes minimizing cost. The parts
              are very easy to replace, which brings down the down-time, hence
              cutting costs sharply.
            </div>
          </div>
        </div>
        <div className="variant-item">
          <div className="float-child2">
            <img
              src={relayclassic}
              alt="Apex Three Phase Classic Relay"
              className="product-image"
            />
          </div>
          <div className="product-description">
            <p>
              <b>Apex Three Phase Submersible Pump Control Panel</b> is used where
              the connection is Three Phase up to 10 H.P.
            </p>
          </div>
        </div>
        <div className="variant-item">
          <div className="float-child2">
            <img
              src={relaycompact}
              alt="Apex Compact Overload Relays"
              className="product-image"
            />
            <img
              src={relaytimer}
              alt="Apex Overload Timer Relay"
              className="product-image"
            />
          </div>
          <div className="product-description">
            <p>
              <b>Apex Automatic Submersible Pump Control Panel</b> is used for
              protection of Heavy Duty Three Phase Submersible-Pumps up to 30 H.P.
              These panels start the pump in star position and when the pump gains
              its rated speed they automatically transfer it from star to delta
              position.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverloadRelays
