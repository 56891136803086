/* global createRoot */

import React from "react";  
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Lato" />


let container = null;

document.addEventListener('DOMContentLoaded', function () {
  // Remove the 'event' parameter if not used
  if (!container) {
    
    container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
});




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
