import "./styles.css"

const NoPage = () => {
  return (
    <div className="no-page">
        No Page     
    </div>
  );
};

export default NoPage;
